$font-base: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-sec: 'Work Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$black: #333;
$white: #fff;
$orange: #FD9426;

$mq-tablets: 1000px;
$mq-tablets-small: 760px;
$mq-mobile: 600px;
$mq-mobile-small: 440px;

@mixin placeholder {
  &::placeholder{ @content }
  &::-moz-placeholder{ @content }
  &::-webkit-input-placeholder{ @content }
}

@mixin object-fit($size){
  object-fit: cover;
  object-position: center;
  display: block;
  max-height: $size;
  min-height: $size;
  height: 100%;
  width: 100%;
}

@mixin tablets{
  @media(max-width: $mq-tablets){
    @content;
  }
}
@mixin smalltablets{
  @media(max-width: $mq-tablets-small){
    @content;
  }
}
@mixin mobile{
  @media(max-width: $mq-mobile){
    @content;
  }
}
@mixin smallmobile{
  @media(max-width: $mq-mobile-small){
    @content;
  }
}
