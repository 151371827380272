@import 'include';

.section-title{
  font-size: 2.7rem;
  font-weight: 400;
  &.mbot-min{
    margin-bottom: 15px;
  }
}
.section-intro{
  font-size: 1.4rem;
}
.subt{
  font-family: $font-sec;
  font-size: 1.4rem;
  font-weight: 600;
}
.subt2{
  font-family: $font-sec;
  margin: 0.5em 0;
  font-size: 1.2rem;
  font-weight: 500;
}
.label{
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $font-sec;
  &.noup{
    text-transform: none;
  }
  &.padded{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
  }
  &.bg-orange{
    background: $orange;
  }
}
  a.label{
    &.bg-orange{
      &:hover{ color: $black; }
    }
  }
.nolink{
  color: inherit;
  text-decoration: none;
  &.nohover{
    color: inherit;
  }
}
.nav-bc{
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 10px;
  list-style: none;
  padding: 0;
  &--li + &--li{
    display: inline-grid;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 10px;
    &:before{
      content: '>';
    }
  }
}
.hero{
  height: 520px;
  color: $white;
  text-align: center;
  position: relative;
  &--cont{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(#000, 0.5);
    display: flex;
    align-items: center;
  }
  &--bg{
    @include object-fit(100%);
  }
  &--title{
    font-size: 2rem;
  }
  &--aftertitle{
    display: inline-block;
    font-size: 0.9rem;
    font-family: $font-sec;
    background: $black;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 10px;
  }

  &--min{
    min-height: 350px;
    height: 350px;
  }
  @include mobile{
    min-height: 400px;
    &--min{
      min-height: 350px;
      height: 350px;
    }
  }
  @include smalltablets{
    &--minmobile{
      min-height: 300px;
      height: 300px;
    }
  }
}

.svc-grid{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 10px;
  text-align: center;
  .svc-title{}
  .svc-img{
    display: block;
    width: 80%;
    max-width: 180px;
    margin: 0 auto;
  }

  @include smalltablets{
    grid-template-columns: repeat(auto-fit, minmax(0, calc(50% - 5px)));
  }
  @include mobile{
    grid-template-columns: repeat(auto-fit, minmax(0, 100%));
    .svc-el{
      max-width: 240px;
      margin: 0 auto;
    }
  }
}
.svc-sidebar{
  display: grid;
  grid-gap: 20px;
  border-left: 1px solid #eee;
  padding-left: 20px;
  &--el{
    .img{
    }
  }

  @include mobile{
    border-left: 0;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(0, 210px));
    justify-content: center;
  }
}
  .svc-el{
    padding: 20px;
    .img{
      margin: 0 auto;
      width: 80%;
      max-width: 220px;
    }
  }


.contact-form{
  .cont{
    max-width: 540px;
    margin: 0 auto;
  }
}

.card-hover{
  transition: box-shadow 0.25s;
  &:hover{
    box-shadow: 0 0 10px rgba($black, 0.2);
  }
}

.card-proy{
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  box-shadow:
    0 2.2px 7.5px rgba(#000, 0.07),
    0 5.3px 18px rgba(#000, 0.05),
    0 9.9px 33.8px rgba(#000, 0.042),
    0 17.6px 60.3px rgba(#000, 0.035),
    0 33px 112.8px rgba(#000, 0.028),
    0 79px 270px rgba(#000, 0.02)
  ;
  background: $white;
  .subt{
    margin-top: 15px;
    margin-bottom: 10px;
  }
  &--cont{
    padding: 10px 15px 20px 22px;
    @include mobile{
      max-height: 200px;
      overflow: auto;
    }
  }
  .button.cta{
    font-size: 1em;
  }
  &--img{
    img{
      @include object-fit(100%);
    }
  }
  @include mobile{
    display: flex;
    flex-direction: column-reverse;
    &--cont{
      flex: 1;
    }
    &--img{
      flex: 1;
    }
  }
}
  a.card-proy{
    text-decoration: none;
    color: currentColor;
  }
  .card-proy.preview{
    grid-template-columns: 1fr;
    .card-proy--cont{
      font-size: 0;
      display: none;
    }
    .card-proy--img{
      img{
        min-height: 280px;
        max-height: 280px;
      }
    }
  }


section#home{
  $pry-negmargin: 80px;
  .pry-container{
    z-index: 10;
    position: relative;
    min-height: 420px;
    margin-top: -$pry-negmargin;
    padding-bottom: 60px;
    .pry-next, .pry-prev{
      display: none; // @todo
      pointer-events: none;
      position: absolute;
      z-index: 11;
      top: 50%;
      background: #f00;
      transform: translateY(-50%);
    }
    .pry-next{
      right: 0;
    }
    // bg
    &:before{
      pointer-events: none;
      content: '';
      position: absolute;
      z-index: -1;
      top: $pry-negmargin;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #DFDBE5;
      background-image:
        linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba($white, 1) 100%),
        url('../img/layout/pattern.svg');
      background-size: auto, 40px;
    }
  }
  .home-servicios{
    margin-top: 70px;
    margin-bottom: 100px;
    .svc-grid{
      margin-top: 50px;
    }
  }
}
  .card-proy--cont{
    .title{
      margin: 0;
    }
    .additional{
      text-transform: uppercase;
      font-size: 0.85em;
      font-family: $font-sec;
      font-weight: 600;
      margin: 10px 0;
    }
    .specs{
      display: grid;
      grid-auto-flow: column;
      grid-gap: 10px;
      margin: 10px 0;
      .spec{
        border-left: 5px solid $orange;
        padding-left: 10px;
      }
      .spec-label{
        font-size: 1em;
      }

      @include mobile{
        display: block;
        .spec{
          margin: 3px 0;
        }
      }
    }
  }

section#blog{}
  @mixin blog-layout{
    display: grid;
    grid-template-columns: minmax(0, 7fr) minmax(190px, 2fr);
    grid-column-gap: 10px;
    grid-row-gap: 60px;
    margin-bottom: 80px;
  }
  .blog-layout{
    @include blog-layout;
    @include mobile{
      grid-row-gap: 0;
      grid-template-columns: auto;
    }
  }

  .post-layout{
    @include blog-layout;
    grid-template-areas:
      'header header'
      'content sidebar';
    .post-header{ grid-area: header;}
    .post-content{ grid-area: content;}
    .post-sidebar{ grid-area: sidebar;}
    @include mobile{
      grid-row-gap: 0;
      grid-template-columns: auto;
      grid-template-areas:
        'header'
        'content'
        'sidebar';
    }
  }
  .post-header{
    display: grid;
    grid-template-columns: 10fr 9fr;
    grid-gap: 15px;
    align-items: center;
    .section-title{
      margin-top: 15px;
    }
    &--img{
      img{
        @include object-fit(400px);
      }
    }
    @include mobile{
      display: flex;
      flex-direction: column-reverse;
      align-items: unset;
      &--img{
        img{
          max-height: 200px;
        }
      }
      &--content{
        flex: 1;
      }
    }
  }

  .posts-grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    &.rel-grid{
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    &--el{
      border: 1px solid #eee;
      .post-head{
        padding: 15px;
      }
      .post-img{
        img{
          @include object-fit(280px);
        }
      }
      .post-title{
        display: flex;
        padding: 10px 15px;
        min-height: 64px;
        align-items: center;
        .subt2{
          margin: 0;
          @include smalltablets{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }

    @include mobile{
      max-width: 310px;
      margin: 0 auto;
      &, &.rel-grid{
        grid-template-columns: auto;
      }
    }
  }

section#servicios{
  .valores-cont{
    margin-top: 100px;
  }
  .valores-title{
    max-width: 620px;
  }

  .svccta-onlymobile{
    display: none;
    @include mobile{
      display: block;
    }
  }
}
  .svcfull-grid{
    column-count: 2;
    column-gap: 30px;
    &--el{
      margin-bottom: 30px;
      break-inside: avoid;
    }
    .intro{
      .section-title{
        margin: 0 0 50px;
      }
    }

    @include smalltablets{
      column-count: auto;
      &--el{
        max-width: 440px;
        margin: 0 auto 30px;
        &.intro{
          max-width: 100%;
        }
      }
    }
  }
  .card-svc{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: column;
    min-height: 250px;
    &--cont{
      padding: 10px 20px;
    }
    &--img{
      img{
        @include object-fit(100%);
      }
    }
    @include mobile{
      grid-template-columns: unset;
      grid-template-rows: 210px auto;
      grid-auto-flow: row;
    }
  }
  .valores-grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 50px 100px;
    @include smalltablets{
      grid-template-columns: auto;
      max-width: 250px;
      margin: 0 auto;
    }
  }
    .valores-card{
      &--img{
        svg{
          background: $orange;
          height: 100px;
          width: 100px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 100%;
        }
      }
    }
  .minisvc-grid{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    @include smalltablets{
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include mobile{
      grid-template-columns: auto;
    }
  }
    .minisvc-icontext{
      display: grid;
      grid-template-columns: min-content 1fr;
      align-items: center;
      grid-gap: 15px;
      h4{
        margin: 0;
      }
      &--ico{
        svg{
          width: 50px;
          height: 50px;
          padding: 5px;
          box-sizing: border-box;
          border-radius: 100%;
          background: $orange;
        }
      }
    }
  .svc-gallery{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    img{
      @include object-fit(100%);
    }

    @include smalltablets{
      max-width: 510px;
      margin: 0 auto;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include mobile{
      max-width: 250px;
      grid-template-columns: auto;
    }
  }

.pagination{
  text-align: center;
  margin: 40px 0 20px;
  .pagy-ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .pagy-item{
    display: inline-block;
    border-radius: 3px;
    margin: 0 10px;
    &.active, &.disabled, a{
      padding: 6px 13px;
    }
    &.active{
      background: $orange;
    }
    a{
      color: inherit;
      text-decoration: none;
    }
  }
}

.nicelist{
  padding-left: 21px;
}

// Slider
.slider{
  *{ max-width: none; }
}
#tns1 > .tns-item{
  font-size: 0.83333rem; // uglyfix; hay que compensar el 20%
                         // de scale adicional al mostrarlo en el slider
}
.proyectos-slider{
  padding: 110px 0;
  .slide{
    width: 520px;
    width: clamp(100px, 70%, 800px);
    transition: transform 0.11s;
  }
}
  .pry-slide-active{
    margin-top: -40px;
    transform: scale(1.2);
    .card-proy{
      min-height: 320px;
    }
  }

