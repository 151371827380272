@import 'include';

html{ -webkit-text-size-adjust: 100%; }

html, body{
  margin: 0;
  padding: 0;
  font: 16px/1.3 $font-base;
}
body{
  background: $white;
  color: $black;
}

*{ max-width: 100%; }

.wrap{
  width: 960px;
  width: clamp(240px, 100%, 1080px);
  margin: 0 auto;
  box-sizing: border-box;
  @media(max-width: #{1080px + 20px}){
    padding-left: 10px;
    padding-right: 10px;
    &--fullmobile{
      width: 100%;
      padding: 0;
    }
  }
}

a{
  color: darken($orange, 10%);
  &:hover{
    color: $orange;
    text-decoration: none;
  }
}

// Sticky footer
body{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header#head,
footer#footer{
  flex: none;
}

#main{
  flex: 1 0 auto;
}
// /Sticky footer

header#head{
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
  @at-root body.nomargin &{
    margin-bottom: 0;
  }
  h1#logo{
    margin: 0;
    a{
      display: block;
      padding-top: 5px;
      @include mobile{
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    svg{
      height: 40px;
    }
  }
  .topbar{
    background: $black;
    color: $white;
    font-size: 0.85rem;
    @include mobile{
      font-size: 0;
      padding-right: 15px;
    }
    a{
      color: currentColor;
      text-decoration: none;
    }
    &--contactitems{
      display: grid;
      grid-auto-flow: column;
      grid-gap: 22px;
      justify-content: flex-end;
      a{
        display: block;
        padding: 10px 0;
      }
      @include mobile{
        grid-gap: 40px;
      }
    }
    i.svg{
      color: darken($white, 12%);
      margin-right: 4px;
    }
  }

  .menubar{
    a{
      color: $black;
      text-decoration: none;
    }
    &--layout{
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 30px;
    }
    .menu{
      display: inline-grid;
      align-items: center;
      font-weight: 600;
      &--ul{
        display: inline-grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: flex-end;
        grid-gap: 2px;
      }
      &--lnk{
        padding: 10px 13px;
      }
    }
    .menu-mobile{
      display: none;
      flex: 1;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      box-sizing: border-box;
    }

    @include smalltablets{
      &--layout{
        grid-template-columns: auto;
        grid-gap: 10px;
        justify-items: center;
      }
      .menu{
        &--lnk{
          display: block;
        }
      }
    }

    @include mobile{
      &--layout{
        justify-items: flex-start;
        grid-template-columns: min-content 1fr;
        position: relative;
        padding: 0;
      }
      .menu-mobile{
        display: flex;
      }
      .menu{
        display: flex;
        width: 100%;
        &--ul{
          position: absolute;
          z-index: 99;
          display: block;
          text-align: right;
          width: 100%;
          top: 100%;
          right: 0;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.25s;
          @for $i from 1 through 10{
            li:nth-child(#{$i}) .menu--lnk{
              transition: all 0.1s 0.25s;
            }
          }
          &.menu-open{
            display: block;
            max-height: 600px;
            transition: max-height 0.5s;
            @for $i from 1 through 10{
              li:nth-child(#{$i}) .menu--lnk{
                transition: all 0.1s #{$i/15}s;
              }
            }
            .menu--lnk{
              opacity: 1;
            }
          }
        }
        &--lnk{
          padding-top: 12px;
          padding-bottom: 12px;
          background: $white;
          opacity: 0;
        }
      }
    }
  }
}

footer#footer{
  a{
    color: inherit;
  }
  .footer-bg{
    background:
      linear-gradient(to bottom, transparent 233px, #000 233px, #000 100%),
      url('../img/layout/footer-construccion.svg') no-repeat center 4px / 1280px,
      url('../img/layout/footer-bg.svg') no-repeat center 40px / 1280px,
    ;
    color: $white;
    padding-top: 250px;
    padding-bottom: 20px;
    font-size: 0.9rem;
    margin-top: 100px;
  }
  .footer-layout{
    display: grid;
    grid-template-columns: minmax(0, 20fr) minmax(0, 40fr) minmax(0, 40fr);
    grid-gap: 20px;
    padding: 20px 0 10px;
    @include mobile{
      grid-template-columns: minmax(0, 50%) minmax(0, 50%);
      grid-template-areas:
        'logo logo'
        'dir mapa';
      word-wrap: break-word;
      .col--logo{ grid-area: logo; }
      .col--mapa{ grid-area: mapa; }
      .col--dir{ grid-area: dir; }
    }
  }

  .footer-icons{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    width: 110px;
  }

  .col--dir{
    ul{
      list-style: none;
      padding: 0;
    }
    li + li{
      margin-top: 20px;
    }
  }

  .footer-mapa{
    display: block;
    img{
      @include object-fit(160px);
      @include mobile{
        max-height: 140px;
        min-height: 140px;
      }
    }
  }

  .by-osom{
    text-align: right;
    font-size: 0.85rem;
    a{
      text-decoration: none;
    }
    .osom-txt{
      display: inline-block;
      font-size: 0;
      svg{
        margin-bottom: -5px;
      }
    }
  }
}

.ctr{ text-align: center; }
.lft{ text-align: left; }
.rgt{ text-align: right; }

// Forms
.p{
  margin: 0.5em 0;
}
.form-error{
  color: #f00;
  &--msg{}
}
.inputtext, textarea, select, button{
  font-family: inherit;
  font-size: 0.9rem;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ccc;
  outline: 0;
  &.full{
    width: 100%;
  }
  &.big{
    font-size: 1.2rem;
  }
}
.inputtext, textarea{
  padding: 15px 18px;
  font-size: 0.95rem;
  background: #EBEBEB;
  border: 0;
  font-weight: 500;
  @include placeholder{
    color: #aaa;
    opacity: 1;
  }
}
textarea{
  min-height: 180px;
  max-height: 520px;
  resize: vertical;
}
.button{
  display: inline-block;
  line-height: inherit;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 15px;
  font-weight: bold;
  color: $black;
  background: $orange;
  border: 0;
  font-size: 1rem;
  font-family: $font-sec;
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  &.full{
    width: 100%;
  }
  &.big{
    padding: 15px 40px;
    font-size: 0.9rem;
  }
  &.w230{
    min-width: 230px;
  }

  &.cta{
    background: transparent;
    padding: 5px 10px;
  }
  &.nopad{
    padding: 0;
  }
}
  a.button{
    white-space: nowrap;
    &:hover{
      color: inherit;
    }
  }

// Misc
ul.nolpad{
  padding-left: 20px;
}
ul.nolist{
  margin: 0;
  list-style: none;
  padding: 0;
}
svg{
  fill: currentColor;
  max-width: none;
}
.svg{
  display: inline-block;
  vertical-align: middle;
  width: 0.9em;
  line-height: 0.9em;
  &.top{
    vertical-align: top;
  }
}

.tmp-srv2col{
  column-count: 2;
  column-gap: 30px;
  &--el{
    break-inside: avoid;
  }
}

// UI
.flash{
  padding: 15px 10px;
  background: #ccc;
  margin: 10px 0;
  &--info{
    background: #81D4FA;
  }
  &--success{
    background: #C5E1A5;
  }
  &--warning{
    background: #FFF59D;
  }
}

::selection{
  background: $orange;
}

.fixed-container {
  position: relative;
  width: 100%;
  height: 380px;
  max-width: 100%;
  &.inline{
    display: inline-block;
  }
}
amp-img.contain img {
  object-fit: contain;
}


@import 'secciones';
